@import url(https://fonts.googleapis.com/css?family=Baloo|Roboto|Work+Sans);
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(/static/media/NARA.49638aa1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  min-height: 100vh;
  text-align: center;
  font-family: "Work Sans", sans-serif;
  color: #222;
  font-size: 13pt;
  line-height: 20pt;
  background-attachment: fixed;
}

footer {
  margin: 20px;
  margin-bottom: 5px;
  color: lightblue;
  background-color: black;
  display: inline-block;
  font-weight: lighter;
  padding: 2px 5px;
  font-size: 10pt;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #701516;
}

b {
  font-weight: bold;
}

.header {
  background: #ffffff;
  padding: 8px;
  display: flex;
  position: fixed;
  width: 100%;
}

.header-name {
  font-size: 20pt;
  font-weight: 900;
  font-family: "Work Sans", sans-serif;
  padding: 8px;
  cursor: pointer;
}

.nav {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
}

.nav-right {
  justify-content: flex-end;
}

.nav-title {
  padding: 8px;
  margin-right: 1em;
  margin-left: 1em;
  cursor: pointer;
  color: #444;
}
.nav-title.current {
  font-weight: bold;
  color: #0a3348;
}
.nav-title:hover {
  font-weight: bold;
  color: #000;
}
.nav-title a {
  color: #444;
  text-decoration: none;
}

.subtitle {
  margin-top: calc(20pt + 34px);
  color: #56ff06;
  font-family: monospace;
  background: black;
  display: inline-block;
  padding: 8px;
}

.row {
  background-color: rgba(245, 245, 245, 0.9);
  margin: 1em;
  margin-top: 1.5em;
  display: flex;
  text-align: left;
}

.row.nav {
  background-color: transparent;
  margin-bottom: 4px;
}

.row.nav .title {
  color: cornsilk;
  font-size: 10pt;
  padding: 2px 8px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid gainsboro;
  padding-right: 30px;
}

.row.heading {
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: white;
}

.row.heading .content {
  width: calc(100% - 12em);
  margin: auto;
}

.row.ux {
  margin-top: 0px;
  margin-bottom: 4px;
}

.row.ux .row-left {
  background-color: rgba(245, 245, 245, 0.5);
}

.row.ux h1 {
  font-family: "Work Sans", sans-serif;
  font-size: 20pt;
  font-weight: 900;
  text-transform: uppercase;
  text-decoration: overline;
}

.row.ux h2 {
  font-weight: 100;
  text-align: center;
  line-height: 1.5em;
}

.row.ux h3 {
  color: #0a3348;
  font-weight: 100;
  margin-bottom: 2px;
}

.row.ux span,
.row.ux li {
  line-height: unset;
  font-size: 11pt;
}

.row-left {
  min-width: 12em;
  max-width: 12em;
  padding: 10px;
  padding-bottom: 1em;
  border-right: 2px solid #ddd;
}

.row-right {
  background-color: white;
  display: inline-block;
  min-height: 100px;
  flex: 1 1 auto;
  float: right;
  padding: 20px;
  padding-bottom: 1em;
}

.Bio-row {
  margin-top: 40%;
}

.Bio-image {
  background-image: url(/static/media/marybethkery.4caa4bb0.jpeg);
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
}

.Bio-contact {
  text-transform: uppercase;
  line-height: 20pt;
  font-size: 10pt;
  color: black;
  padding: 5px;
}

.Bio-intro-detail {
  font-size: 10pt;
  font-style: italic;
}

.project-header {
  color: white;
  text-align: center;
  margin: auto;
  top: 0.25em;
}

.paper {
  margin: 10px;
}

.paper-image {
  background-size: cover;
  background-repeat: no-repeat;
}

.project-title {
  font-weight: bold;
  width: 100%;
  margin-bottom: 10px;
  text-align: right;
}

.art-header {
  text-align: left;
  margin-left: 20px;
  opacity: 1;
  top: 0.25em;
}

.cube {
  background-color: rgba(245, 245, 245, 0.9);
  margin: 0.5em;
  margin-top: 1em;
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  min-width: 25vw;
  max-width: 30vw;
}

.cubeContent {
  display: block;
  height: 25vw;
  width: 100%;
}

.cubeDesc {
  background-color: white;
  display: inline-block;
  min-height: 5vw;
  padding: 10px;
  padding-bottom: 1em;
}

.Portfolio {
  -webkit-column-count: 3;
  -webkit-column-gap: 0px;
  -moz-column-count: 3;
  -moz-column-gap: 0px;
  column-count: 3;
  grid-column-gap: 0px;
  column-gap: 0px;
}

.artImage {
  padding: 5px;
  width: calc(100% - 10px);
  height: auto;
  flex-flow: wrap;
  justify-content: flex-start;
  vertical-align: top;
  max-height: 35vw;
  background-size: cover;
}

.githubcard {
  margin: 1.5em;
}

@media (max-width: 1200px) {
  .Portfolio {
    -webkit-column-count: 4;
    column-count: 4;
  }
}
@media (max-width: 900px) {
  .Portfolio {
    -webkit-column-count: 2;
    column-count: 2;
  }

  .cube {
    max-width: calc(50vw - 3em);
  }

  .artImage {
    max-height: none;
  }
}
@media (max-width: 700px) {
  .Portfolio {
    -webkit-column-count: 1;
    column-count: 1;
  }

  .cube {
    max-width: 100%;
  }

  .artImage {
    max-height: none;
  }
}

.imageFrame {
  width: 50vw;
  padding: 10px;
  margin: auto;
  margin-bottom: 2em;
}

.imageFrame img {
  width: 100%;
}

.imageCaption {
  text-align: justify;
  font-size: small;
  color: #888;
  width: 90%;
  margin: auto;
  line-height: 1.25em;
}

